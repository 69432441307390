<template>
  <div class="flex flex-col items-center justify-start group">
    <div class="relative">
      <div class="product-is-new absolute z-20 m-2" v-if="selectedVariant?.isNew">
        {{ $t('app.new') }}
      </div>

      <div class="group" key={selectedVariant.variantId}>

        <DiscountPercent :selected-variant="selectedVariant" v-if="!showRateNextToPrice"/>

<!--        <div-->
<!--            class="flex justify-end z-30 cursor-pointer hover:text-primary sm:invisible sm:group-hover:visible"-->
<!--            v-if="isAuth"-->
<!--            @click="addWishlist(selectedVariant)">-->

<!--          <HeartFilledIcon-->
<!--              class="text-red h-8 w-8 sm:w-7 sm:h-7 absolute z-30 rounded-full p-1 m-2"-->
<!--              v-if="selectedVariant.isFavorited"/>-->
<!--          <HeartIcon-->
<!--              class="text-lightgray-400 h-8 w-8 sm:w-7 sm:h-7 absolute z-30 rounded-full p-1 m-2"-->
<!--              v-else/>-->
<!--        </div>-->
<!--        <div-->
<!--            class="flex justify-end z-30 cursor-pointer hover:text-primary sm:invisible sm:group-hover:visible"-->
<!--            v-if="!isAuth"-->
<!--            @click="authModalIsVisible = true">-->
<!--          <HeartIcon-->
<!--              class="text-lightgray-400 h-8 w-8 sm:w-7 sm:h-7 absolute z-30 rounded-full p-1 m-2"-->
<!--              />-->
<!--        </div>-->

        <div class="rounded-lg relative ">
          <NuxtLinkLocale :to="selectedVariant?.url ? checkAndAddSlash(selectedVariant.url) : '#'" class="block min-h-[360px] max-h-[360px] overflow-hidden bg-white">
            <div class="flex absolute z-20 justify-center text-center items-center w-full h-full" v-if="!selectedVariant?.inStock">
              <span class="product-out-of-stock"><StockOutText /></span>
            </div>

            <div class="flex absolute z-20 justify-center text-center items-end w-full h-full" v-if="selectedVariant?.isFreeShipping">
                      <span class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                        <BoltIcon class="h-5 w-5 mr-2 fill-primary"/>
                        <span>{{ $t('app.free-shipping') }}</span>
                      </span>
            </div>

              <NuxtImg
                  v-if="selectedVariant?.thumbnail"
                  :src="getImage(selectedVariant.thumbnail)"
                  width="368"
                  height="552"
                  fit="outside"
                  loading="lazy"
                  :placeholder="[50, 50, 93, 3]"
                  :quality="90"
                  :alt="selectedVariant?.title"
                  :class="{'product-img-out-of-stock' : !selectedVariant?.inStock}"
                  class="z-1 object-contain object-center aspect-2/3"
              />

              <NuxtImg
                  v-else
                  fit="outside"
                  alt=""
                  class="z-1 object-contain object-center aspect-2/3"
                  width="368"
                  height="552"
                  src="/images/no-image.png"
              />
          </NuxtLinkLocale>

          <!-- Ürün Seçenekleri -->
          <SectionProductCardGroupOptions :product="product" />

        </div>
        <div class="flex flex-col text-base text-primary">
          <div class="card-brand mt-2" v-if="!hideBrand">
            {{ product.brandName }}
          </div>
          <div class="font-normal text-[15px] line-clamp-2">{{ selectedVariant?.title }}</div>

          <!-- Fiyat Bilgisi -->
          <SectionProductCardPriceView :variant="selectedVariant" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {HeartIcon, BoltIcon} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartFilledIcon} from "@heroicons/vue/24/solid";
import TriangleIcon from '~/assets/icons/triangle.svg'
import AddToCartIcon from '~/assets/icons/add-to-cart.svg'

import {collect} from "collect.js";
import DiscountPercent from "~/components/DiscountPercent.vue";
import PriceUpdateModeDescriptionForB2B from "~/components/PriceUpdateModeDescriptionForB2B.vue";
import {customRound} from "~/composables/useHelper";

const props = defineProps(['product', 'selectedWarehouse'])

const {$listen, $unlisten} = useNuxtApp()

const storeConfig = useStoreConfig()
const { baseConfig, showRateNextToPrice } = storeToRefs(storeConfig)

const {
  selectedWarehouse,
  hidePriceForSoldOut,
  showListPrice,
  hideBrand
} = useListing()

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

const isShowcaseMode = computed(() => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0)

const {addToBasket, addCartLoading} = await useBasket()
const {isWholesaleUser, authModalIsVisible} = await useCustomer()

</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>